<template>
  <q-page class="completePage">
    <div class="page-content">
      <div class="container">
        <div class="sectionInner">
          <div class="sectionLeft">
            <div class="orderThankyou">
              <div class="row">
                <div class="col-xs-12 col-sm-3 col-md-2 col-lg-2 text-center">
                  <SvgIcon icon="confirmation-check" class="checki" />
                </div>
                <div class="col-xs-12 col-sm-9 col-md-10 col-lg-10">
                  <h1 class="orderReviewTitle"
                    >Thank you, your order has been placed.</h1
                  >
                  <WriteFeedback
                    v-if="
                      businessConfigurations.isBetaEnabled &&
                      businessConfigurations.enableBetaCustomerReview
                    "
                  />
                  <p>An email confirmation has been sent to you.</p>
                  <p>
                    <strong>
                      Your order Number:
                      {{ recentOrderDetail.orderNumber }}
                    </strong>
                  </p>
                  <!-- <p>
                    You have until <strong>1:45am on Fri, Mar 25</strong> to
                    make changes to your order. You can track your order
                    <router-link
                      :to="{
                        name: 'Orders',
                      }"
                    >
                      <strong>here</strong>
                    </router-link>
                  </p> -->
                  <ul class="trackCompleteList">
                    <li
                      v-for="(
                        fulfillment, index
                      ) of recentOrderDetail.orderFulfillments"
                      :key="`${fulfillment.fulfillmentMethodID}-${index}`"
                    >
                      <div class="row q-col-gutter-md items-center">
                        <div class="col-md-auto hd">
                          <SvgIcon
                            color="primaryOnBody"
                            :icon="fulfillment.codeName"
                            class="q-mr-sm vm"
                          />
                          {{ fulfillment.orderNumber }}
                        </div>
                        <div class="col-md-6 link" v-if="isLoggedIn">
                          <router-link
                            :to="{
                              name: 'TrackOrder',
                              params: { orderId: fulfillment.orderID },
                            }"
                            class="q-pr-md"
                          >
                            View Order
                          </router-link>
                          <router-link
                            v-if="
                              fulfillment.allowModification &&
                              fulfillment.codeName != 'sd'
                            "
                            :to="{
                              name: 'OrderEdit',
                              params: { orderId: fulfillment.orderID },
                            }"
                            class="q-pr-md"
                          >
                            Edit Order
                          </router-link>
                        </div>
                        <div
                          class="col-md-12 q-pt-sm"
                          v-if="fulfillment.codeName !== 'sd' && isLoggedIn"
                        >
                          <p>
                            You can edit your order until it is moved to
                            processing. Once your order status is changed to
                            processing, it can no longer be edited or modified.
                            <!-- You have until
                            <strong>
                              {{
                                fulfillment.allowEditTillDateTime
                                  | formatDate('h:mm A, ddd, MMM DD')
                              }}
                            </strong>
                            to make changes to your order. -->
                          </p>
                        </div>
                      </div>
                    </li>
                  </ul>
                  <!-- <p class="orderReviewLink">
                    <a
                      href="javascript:void(0)"
                      @click="showOrderReview = true"
                    >
                      Review your order<SvgIcon icon="arrow-angle-right" />
                    </a>
                  </p> -->
                </div>
              </div>
            </div>
            <div class="orderReviewInformation" v-show="showOrderReview">
              <div class="row title">
                <div class="col-xs-6 col-sm-8"><h2>Order Information</h2></div>
                <div class="col-xs-6 col-sm-4 text-right">
                  <a
                    href="javascript:void(0)"
                    @click="
                      downloadOrderInvoice(recentOrderDetail.orderFulfillments)
                    "
                    v-if="!isMobile"
                  >
                    <q-icon name="file_download" class="q-mr-sm" /> Download
                    Invoice
                  </a>
                </div>
                <div class="col-12 col-md-6" v-if="!isMobile">
                  <Alert
                    v-if="orderCompleteAlert"
                    className="q-mt-sm"
                    :variant="orderCompleteAlert.variant"
                    :show="10"
                  >
                    <span v-html="orderCompleteAlert.message" />
                  </Alert>
                </div>
              </div>
              <div
                class="orderItems"
                v-for="(
                  fulfillment, index
                ) of recentOrderDetail.orderFulfillments"
                :key="`${fulfillment.fulfillmentMethodID}-${index}`"
              >
                <!-- New UI for Order -->
                <div class="cart-section">
                  <div class="cart-order-header">
                    <div class="cart-order-title">
                      <div class="row items-center">
                        <div class="col-auto">
                          <span class="icon">
                            <SvgIcon
                              color="primaryOnBody"
                              :icon="fulfillment.codeName"
                            />
                          </span>
                        </div>
                        <div class="col q-pl-sm"
                          ><strong>{{ fulfillment.displayName }}</strong></div
                        >
                      </div>
                    </div>
                    <div class="cart-order-price text-right"> Total </div>
                  </div>

                  <div
                    class="cart-order"
                    v-for="item of fulfillment.orderLineItemsModelsList"
                    :key="item.id"
                  >
                    <div
                      :class="[
                        'cart-product sd-spacing q-ml-none',
                        item.linkedProducts && item.linkedProducts.length > 0
                          ? 'substitution-product'
                          : item.allowSubstitute
                          ? 'substitution-product'
                          : '',
                      ]"
                    >
                      <div class="cart-produt-data">
                        <div class="produt-data flex items-start">
                          <div class="product-image">
                            <box-image
                              :link="{
                                name: 'Detail',
                                params: { seoName: item.seoName },
                              }"
                              :imageSrc="item.image"
                              :width="isMobile ? '80px' : '86px'"
                            />
                          </div>
                          <div class="co-product-details">
                            <div class="product--title">
                              <h3 class="ellipsis">
                                <router-link
                                  :to="{
                                    name: 'Detail',
                                    params: { seoName: item.seoName },
                                  }"
                                >
                                  {{ item.productTittle }}
                                </router-link>
                              </h3>
                              <div
                                class="cart--labels q-mt-sm"
                                v-if="item.allowSubstitute"
                              >
                                <span class="allowed">
                                  Substitution Allowed
                                  <SvgIcon
                                    icon="info-outline"
                                    class="q-ml-sm"
                                  />
                                </span>
                              </div>
                              <q-banner
                                inline-actions
                                dense
                                rounded
                                class="alert-warning alert-sm q-py-sm q-mt-sm text-subtitle2 inline-block"
                                v-if="item.frequency > 0"
                              >
                                Recurring in every
                                <strong
                                  v-text="getSubFrequency(item.frequency)"
                                ></strong>
                              </q-banner>
                              <div
                                class="cart--instruction text-body2 word-break-word q-mt-sm"
                                v-if="item.instruction"
                              >
                                <strong class="text-grey-6"
                                  >Item instruction:</strong
                                >
                                <br />
                                {{ item.instruction || '(not set)' }}
                              </div>
                              <div class="cart--quantity q-mt-sm"
                                >{{ item.qty }} x
                                {{ item.price | currency }}</div
                              >
                            </div>
                            <div class="price">
                              <span
                                class="original-price"
                                v-html="
                                  $options.filters.currency(item.totalPrice)
                                "
                                v-if="item.totalPrice != item.finalPrice"
                              />
                              <span
                                :class="
                                  item.totalPrice != item.finalPrice
                                    ? 'discount-price'
                                    : ''
                                "
                                v-html="
                                  $options.filters.currency(item.finalPrice)
                                "
                              />
                            </div>
                          </div>
                        </div>
                      </div>

                      <!-- Linked Item -->
                      <div
                        class="substitution-product--block"
                        v-if="
                          item.linkedProducts && item.linkedProducts.length > 0
                        "
                      >
                        <div
                          class="substitution-product produt-data flex items-center"
                          v-for="(linkedProduct, index) in item.linkedProducts"
                          :key="`${linkedProduct.orderLineItemID}${index}`"
                        >
                          <div class="product-image">
                            <q-avatar square>
                              <box-image
                                :link="
                                  linkedProduct.isSellable
                                    ? {
                                        name: 'Detail',
                                        params: {
                                          seoName: linkedProduct.seoName,
                                        },
                                      }
                                    : ''
                                "
                                :imageSrc="linkedProduct.image"
                                width="40px"
                              />
                            </q-avatar>
                            <q-avatar flat>
                              <SvgIcon icon="linked" />
                            </q-avatar>
                          </div>
                          <div class="co-product-details">
                            <div class="product--title">
                              <h4>
                                <router-link
                                  v-if="linkedProduct.isSellable"
                                  :to="{
                                    name: 'Detail',
                                    params: { seoName: linkedProduct.seoName },
                                  }"
                                  v-html="linkedProduct.productTittle"
                                />
                                <template v-else>
                                  {{ linkedProduct.productTittle }}
                                </template>
                                <q-tooltip
                                  max-width="180px"
                                  content-class="bg-dark"
                                >
                                  {{ linkedProduct.productTittle }}
                                </q-tooltip>
                              </h4>
                            </div>
                            <div class="price">
                              <span
                                class="original-price"
                                v-html="
                                  $options.filters.currency(
                                    linkedProduct.totalPrice
                                  )
                                "
                                v-if="
                                  linkedProduct.totalPrice !=
                                  linkedProduct.finalPrice
                                "
                              />
                              <span
                                :class="
                                  linkedProduct.totalPrice !=
                                  linkedProduct.finalPrice
                                    ? 'discount-price'
                                    : ''
                                "
                                v-html="
                                  $options.filters.currency(
                                    linkedProduct.finalPrice ||
                                      linkedProduct.totalPrice
                                  )
                                "
                              />
                            </div>
                            <div class="flex product-edit--options">
                              <div class="product-quantity">
                                <span
                                  v-html="
                                    `${
                                      linkedProduct.qty
                                    } X ${$options.filters.currency(
                                      linkedProduct.price
                                    )}`
                                  "
                              /></div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <!-- Linked Item -->
                    </div>
                  </div>
                </div>
                <!-- New UI for Order -->

                <div class="orderReviewInfo">
                  <div class="row q-col-gutter-x-lg">
                    <div
                      class="col-xs-12 col-sm-6 col-md-4 q-mt-md"
                      v-if="fulfillment && fulfillment.deliveryInstruction"
                    >
                      <h5>Order Instruction</h5>
                      <p class="text-justify text-caption word-break-word">
                        {{ fulfillment.deliveryInstruction }}
                      </p>
                    </div>
                    <div
                      class="col-xs-12 col-sm-6 col-md-4 q-mt-md"
                      v-if="
                        fulfillment.pickupStore &&
                        fulfillment.pickupStore.addressId
                      "
                    >
                      <h5>Pickup Store</h5>
                      <p>
                        {{ fulfillment.pickupStore.name }}<br />
                        <span
                          v-html="
                            $options.filters.formatAddress(
                              fulfillment.pickupStore
                            )
                          "
                        />
                      </p>
                    </div>
                    <div
                      class="col-xs-12 col-sm-6 col-md-4 q-mt-md"
                      v-if="
                        fulfillment.shippingAddress &&
                        fulfillment.shippingAddress.firstName
                      "
                    >
                      <h5>{{
                        fulfillment.codeName === 'sd'
                          ? 'Ship To'
                          : 'Delivery Address'
                      }}</h5>
                      <p>
                        {{
                          fulfillment.shippingAddress.firstName +
                          ' ' +
                          fulfillment.shippingAddress.lastName
                        }}
                        <span
                          v-if="fulfillment.shippingAddress.isDefault"
                          class="default q-ml-sm"
                        >
                          Default
                        </span>
                        <!-- <span class="default">Default</span> -->
                        <br />
                      </p>
                      <p
                        v-html="
                          $options.filters.formatAddress(
                            fulfillment.shippingAddress
                          )
                        "
                      />
                    </div>
                    <div
                      class="col-xs-12 col-sm-6 col-md-4 q-mt-md"
                      v-if="
                        (fulfillment.timeSlot &&
                          fulfillment.timeSlot.availableSlotID) ||
                        (fulfillment.pickupType && fulfillment.codeName == 'sp')
                      "
                    >
                      <template
                        v-if="
                          fulfillment.pickupType && fulfillment.codeName == 'sp'
                        "
                      >
                        <h5>Pickup Method</h5>
                        <p class="q-pb-md">{{
                          fulfillment.pickupType == 1 ? 'In Store' : 'Curbside'
                        }}</p></template
                      >
                      <template
                        v-if="
                          fulfillment.timeSlot &&
                          fulfillment.timeSlot.availableSlotID
                        "
                      >
                        <h5>Time Slot</h5>
                        <p>
                          {{ fulfillment.timeSlot.bookingDate | formatDate }}
                          {{ fulfillment.timeSlot.slotTime }}
                        </p>
                      </template>
                    </div>

                    <div
                      v-if="
                        fulfillment.pickupPerson &&
                        fulfillment.pickupPerson.primaryUserFirstName
                      "
                      class="col-xs-12 col-sm-6 col-md-4 q-mt-md"
                    >
                      <h5>{{
                        fulfillment.codeName === 'scd'
                          ? 'Deliver To'
                          : 'Primary Pickup Person'
                      }}</h5>
                      <h5 v-show="fulfillment.codeName === 'scd'">
                        Primary Person
                      </h5>
                      <p v-if="fulfillment.pickupPerson.primaryUserFirstName">
                        Name:
                        {{ fulfillment.pickupPerson.primaryUserFirstName }}
                        {{ fulfillment.pickupPerson.primaryUserLastName }}
                      </p>
                      <!-- <p v-if="fulfillment.pickupPerson.pickUpByPhone1">
                          Phone:
                          {{
                            maskPhoneNumber(
                              fulfillment.pickupPerson.pickUpByPhone1
                            )
                          }}
                        </p> -->
                      <p v-if="fulfillment.pickupPerson.pickUpByEmail1">
                        Email:
                        {{ fulfillment.pickupPerson.pickUpByEmail1 }}
                      </p>
                    </div>
                    <div
                      v-if="
                        fulfillment.pickupPerson &&
                        fulfillment.pickupPerson.alternateUserFirstName
                      "
                      class="col-xs-12 col-sm-6 col-md-4 q-mt-md"
                    >
                      <h5>Alternate Person</h5>
                      <p>
                        Name:
                        {{ fulfillment.pickupPerson.alternateUserFirstName }}
                        {{ fulfillment.pickupPerson.alternateUserLastName }}
                      </p>
                      <!-- <p v-if="fulfillment.pickupPerson.pickUpByPhone2">
                        Phone:
                        {{
                          maskPhoneNumber(
                            fulfillment.pickupPerson.pickUpByPhone2
                          )
                        }}
                      </p> -->
                      <p v-if="fulfillment.pickupPerson.pickUpByEmail2">
                        Email:
                        {{ fulfillment.pickupPerson.pickUpByEmail2 }}
                      </p>
                    </div>
                    <div class="col-xs-12 col-sm-6 col-md-4 q-mt-md">
                      <h5>Payment Method</h5>
                      <p
                        v-if="
                          getPaymentDetailsForFulfillment(
                            fulfillment.codeName
                          ) == 'PAY_AT_STORE'
                        "
                      >
                        <SvgIcon
                          size="44px"
                          icon="pay-at-store"
                          class="q-mr-sm"
                        />
                        Pay at store
                      </p>
                      <p
                        v-else-if="
                          getPaymentDetailsForFulfillment(
                            fulfillment.codeName
                          ) == 'CARD' && recentOrderDetail.orderPaymentDetail
                        "
                      >
                        <span class="text-weight-bold">
                          Credit or debit card</span
                        >
                        <br />
                        <span :class="`paymentIcon paymentIcon-left`">
                          <img
                            :src="`images/payment/${
                              getCardType(
                                recentOrderDetail.orderPaymentDetail.cardType
                              ).logo
                            }`"
                            :title="
                              getCardType(
                                recentOrderDetail.orderPaymentDetail.cardType
                              ).title
                            "
                          />
                        </span>

                        {{
                          recentOrderDetail.orderPaymentDetail.cardHolderName
                        }}
                        <span
                          v-if="recentOrderDetail.orderPaymentDetail.isDefault"
                          class="default q-ml-sm"
                        >
                          Default </span
                        ><br />
                        xxxx-xxxx-xxxx-{{
                          recentOrderDetail.orderPaymentDetail.cardLast4Digits
                        }}<br />
                        Expires :
                        {{ recentOrderDetail.orderPaymentDetail.cardExpiry }}
                        <span
                          class="text-caption"
                          v-html="acknowledgeAcceptanceMessage"
                        />
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="sectionRight">
            <div
              class="orderCompleteSummary"
              v-if="recentOrderDetail.orderSummary"
            >
              <h3>Order Summary</h3>
              <div class="orderSummaryInner">
                <div class="orderSummaryTotal">
                  <ul>
                    <!-- <li>
                      <span>Cart Total</span>
                      {{ recentOrderDetail.orderSummary.subTotal | currency }}
                    </li> -->
                    <template
                      v-for="(
                        fulfillment, index
                      ) in recentOrderDetail.orderFulfillments"
                    >
                      <li :key="`${fulfillment.fulfillmentMethodID}-${index}`">
                        <span>Sub Total</span>
                        <!-- <span>{{ fulfillment.displayName }} Total</span> -->
                        {{ fulfillment.subTotal | currency }}
                      </li>
                      <template
                        v-if="
                          fulfillment.codeName == 'sd' &&
                          fulfillment.shippingRate !== null
                        "
                      >
                        <div :key="index">
                          <li
                            ><span>Shipping Fee</span>

                            {{
                              fulfillment.shippingRate == 0
                                ? 'Free'
                                : $options.filters.currency(
                                    fulfillment.shippingRate,
                                    false,
                                    true
                                  )
                            }}
                          </li>
                        </div>
                      </template>
                    </template>
                    <template v-if="recentOrderDetail.orderSummary.tipAmount">
                      <li>
                        <span>Tip</span>
                        {{
                          recentOrderDetail.orderSummary.tipAmount
                            | currency(false, true)
                        }}
                      </li>
                    </template>
                    <template
                      v-if="
                        recentOrderDetail.orderSummary.additionalCharges &&
                        recentOrderDetail.orderSummary.additionalCharges.length
                      "
                    >
                      <li
                        v-for="additionalCharge of recentOrderDetail
                          .orderSummary.additionalCharges"
                        :key="additionalCharge.chargeName"
                      >
                        <span>{{ additionalCharge.chargeName }}</span>
                        {{
                          additionalCharge.chargeAmount | currency(false, true)
                        }}
                      </li>
                    </template>

                    <!-- <li v-if="recentOrderDetail.orderSummary.taxes">
                      <span>Tax</span>
                      {{
                        recentOrderDetail.orderSummary.taxes
                          | currency(false, true)
                      }}
                    </li> -->
                    <template v-if="recentOrderDetail.orderSummary.discount">
                      <li>
                        <span>Discount Applied</span>
                        {{ recentOrderDetail.orderSummary.discount | currency }}
                      </li>
                    </template>

                    <li v-if="recentOrderDetail.orderSummary.buydown">
                      <span>Other Discount</span>
                      -
                      {{
                        recentOrderDetail.orderSummary.buydown | currency(false)
                      }}
                    </li>

                    <li v-if="recentOrderDetail.orderSummary.appliedLnsDollars">
                      <span>Loyalty Dollars Applied</span>
                      -
                      {{
                        recentOrderDetail.orderSummary.appliedLnsDollars
                          | currency
                      }}
                    </li>

                    <!-- <q-separator class="q-mt-xs" /> -->

                    <!-- <li v-if="recentOrderDetail.orderSummary.cartTotal">
                      <span> Sub Total</span>
                      {{
                        (recentOrderDetail.orderSummary.cartTotal -
                          (recentOrderDetail.orderSummary &&
                          recentOrderDetail.orderSummary.taxes
                            ? recentOrderDetail.orderSummary.taxes
                            : 0) -
                          (recentOrderDetail.orderSummary &&
                          recentOrderDetail.orderSummary.appliedLnsDollars
                            ? recentOrderDetail.orderSummary.appliedLnsDollars
                            : 0))
                          | currency
                      }}
                    </li> -->

                    <template v-if="recentOrderDetail.orderSummary.otherTax">
                      <li>
                        <span>Tax</span>
                        {{
                          recentOrderDetail.orderSummary.otherTax
                            | currency(false, true)
                        }}
                      </li>
                    </template>
                    <template
                      v-if="recentOrderDetail.orderSummary.occupationalTax"
                    >
                      <li>
                        <span>Occupational Tax</span>
                        {{
                          recentOrderDetail.orderSummary.occupationalTax
                            | currency(false, true)
                        }}
                      </li>
                    </template>
                    <template
                      v-if="
                        selectedFulfillmentCodeName &&
                        selectedFulfillmentCodeName == 'scd' &&
                        recentOrderDetail.orderSummary.shippingRate
                      "
                    >
                      <li>
                        <span>Delivery Fee</span>
                        {{
                          recentOrderDetail.orderSummary.shippingRate
                            | currency(false, true)
                        }}
                      </li>
                    </template>
                    <template
                      v-if="
                        recentOrderDetail.appliedLnsCoupons &&
                        recentOrderDetail.appliedLnsCoupons.length
                      "
                    >
                      <p class="q-my-sm applied-coupons"
                        >Applied Loyalty Coupon(s)</p
                      >
                      <q-separator />
                      <li
                        v-for="appliedLnsCoupon in recentOrderDetail.appliedLnsCoupons"
                        :key="appliedLnsCoupon.id"
                      >
                        <!-- <div class="text-left"> -->
                        <span>
                          {{ appliedCouponName(appliedLnsCoupon.name) }}
                          <q-tooltip max-width="180px" content-class="bg-dark">
                            {{ appliedLnsCoupon.name }}
                          </q-tooltip>
                        </span>
                        <!-- <span class="float-right">
                            <span class="vertical-middle	"> -->
                        - {{ appliedLnsCoupon.amount | currency }}
                        <!-- </span>
                          </span> -->
                        <!-- </div> -->
                      </li>
                    </template>
                    <template
                      v-if="
                        recentOrderDetail.appliedDiscountCoupons &&
                        recentOrderDetail.appliedDiscountCoupons.length
                      "
                    >
                      <p class="q-my-sm applied-coupons"
                        >Applied Discount Coupons</p
                      >
                      <q-separator />
                      <li
                        v-for="appliedDiscountCoupon in recentOrderDetail.appliedDiscountCoupons"
                        :key="appliedDiscountCoupon.id"
                      >
                        <!-- <div class="text-left"> -->
                        <span>
                          {{ appliedDiscountCoupon.couponCode }}
                          <q-tooltip max-width="180px" content-class="bg-dark">
                            {{ appliedDiscountCoupon.name }}
                          </q-tooltip>
                        </span>
                        <!-- <span class="float-right">
                            <span class="vertical-middle	"> -->
                        {{ appliedDiscountCoupon.amount | currency }}
                        <!-- </span>
                          </span> -->
                        <!-- </div> -->
                      </li>
                    </template>
                    <!-- <template
                      v-if="recentOrderDetail.orderSummary.appliedLnsDollars"
                    >
                      <q-separator class="q-mt-sm" />
                      <li>
                        <span><strong>Loyalty Dollars Applied</strong></span>
                        <strong>
                          -
                          {{
                            recentOrderDetail.orderSummary.appliedLnsDollars
                              | currency
                          }}
                        </strong>
                      </li>
                    </template> -->

                    <li class="total">
                      <span>Order Total</span>
                      {{
                        recentOrderDetail.orderSummary.finalPayAmount | currency
                      }}
                    </li>
                  </ul>
                </div>
                <q-list
                  bordered
                  class="rounded-borders orderSummaryDiscount"
                  v-if="calculateTotalSavings"
                >
                  <q-expansion-item dense-toggle expand-separator>
                    <template v-slot:header>
                      <q-item-section avatar>
                        <SvgIcon size="2.5rem" icon="cart-savings" />
                      </q-item-section>

                      <q-item-section>
                        <span>Your total</span>
                        Savings!
                      </q-item-section>

                      <q-item-section
                        side
                        v-html="
                          $options.filters.currency(
                            calculateTotalSavings,
                            false
                          )
                        "
                      />
                    </template>
                    <!-- <q-card>
                      <q-card-section>
                        <ul>
                          <li><span>Great Value Pack</span> -$78.56</li>
                          <li><span>Great Value Pack</span> -$78.56</li>
                        </ul>
                      </q-card-section>
                    </q-card> -->
                  </q-expansion-item>
                </q-list>
                <div class="orderSummaryAction text-center">
                  <p>Order Number: {{ recentOrderDetail.orderNumber }}</p>
                  <div class="form-action">
                    <q-btn
                      unelevated
                      no-caps
                      rounded
                      outline
                      color="tertiary"
                      label="Continue Shopping"
                      class="full-width q-btn-lg radius-lg"
                      to="/"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </q-page>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { debounce } from 'quasar'

import { maskPhoneNumber, appliedCouponName, getCardType } from 'src/utils'
import WriteFeedback from 'components/common/modes/BetaFeedback.vue'

export default {
  name: 'PageOrderReview',
  /* async preFetch({ store, currentRoute, redirect }) {
    let { orderNumber } = currentRoute.params,
      recentOrderNumber = store.getters['order/recentOrderNumber'],
      isLoggedIn = store.getters['auth/isLoggedIn'],
      orderInfo = store.getters['order/orderDetailById'](orderNumber)

    //  order complete page on refresh
    if (!orderInfo || !orderInfo.orderID) {
      await store.dispatch('cart/getOrderThankYou', orderNumber)
      orderInfo = store.getters['order/orderDetailById'](orderNumber)
      recentOrderNumber = store.getters['order/recentOrderNumber']
    }

    if (orderNumber !== recentOrderNumber) {
      redirect({
        name: 'Orders',
      })
    }

    if (!isLoggedIn && orderInfo.customerID)
      await store.dispatch('cart/fetchGuestUserInfo', orderInfo.customerID)
  }, */
  components: {
    WriteFeedback,
  },
  props: {
    orderNumber: {
      type: String,
      default: '',
    },
  },
  meta() {
    return {
      title: 'Thank You',
      ...this.commonMeta,
    }
  },
  data() {
    return {
      showOrderReview: true,
    }
  },
  computed: {
    recentOrderDetail() {
      return this.orderDetailById(this.recentOrderNumber)
    },
    fulfillments() {
      return this.recentOrderDetail.fulfillments
    },
    ...mapGetters('order', [
      'recentOrderNumber',
      'orderDetailById',
      'alertData',
      'getPaymentDetailsForFulfillment',
    ]),
    ...mapGetters('customer', ['customer']),
    ...mapGetters('persisted', ['selectedFulfillmentCodeName']),
    ...mapGetters('auth', ['currentUser']),
    ...mapGetters('guest', ['guestUser']),
    orderCompleteAlert() {
      return this.alertData('orderCompleteAlert')
    },
    appliedLnsCouponsAmount() {
      let amount = 0
      if (
        this.recentOrderDetail &&
        this.recentOrderDetail?.appliedLnsCoupons?.length
      ) {
        for (let coupon of this.recentOrderDetail.appliedLnsCoupons) {
          amount += Math.abs(coupon.amount)
        }
      }
      return amount
    },
    appliedDiscountCouponsAmount() {
      let amount = 0
      if (
        this.recentOrderDetail &&
        this.recentOrderDetail?.appliedDiscountCoupons?.length
      ) {
        for (let coupon of this.recentOrderDetail.appliedDiscountCoupons) {
          amount += Math.abs(coupon.amount)
        }
      }
      return amount
    },
    calculateTotalSavings() {
      let amount = 0
      if (this.recentOrderDetail && this.recentOrderDetail?.orderSummary) {
        if (this.recentOrderDetail.orderSummary?.discount)
          amount += Math.abs(this.recentOrderDetail.orderSummary.discount)
        if (this.recentOrderDetail.orderSummary?.buydown)
          amount += Math.abs(this.recentOrderDetail.orderSummary.buydown)
        if (this.recentOrderDetail.orderSummary?.appliedLnsDollars)
          amount += Math.abs(
            this.recentOrderDetail.orderSummary.appliedLnsDollars
          )
      }
      if (this.appliedLnsCouponsAmount) amount += this.appliedLnsCouponsAmount
      if (this.appliedDiscountCouponsAmount)
        amount += this.appliedDiscountCouponsAmount
      return amount
    },
    currentUserName() {
      let name = ''
      if (this.isLoggedIn)
        name = this.currentUser.firstName + ' ' + this.currentUser.lastName
      else if (this.guestUser)
        name = this.guestUser.firstName + ' ' + this.guestUser.lastName
      return name
    },
    acknowledgeAcceptanceMessage() {
      let msg = ''
      if (this.recentOrderDetail.isAcknowledgeAccepted) {
        msg += `<br />Digitally Authorized by <strong>${
          this.currentUserName
        }</strong> on ${this.$options.filters.formatDate(
          this.recentOrderDetail.paymentAcknowledgementDate,
          'HH:mm MMM DD, YYYY'
        )} [${this.recentOrderDetail.paymentAcknowledgementAcceptedFromIP}]`
      }
      return msg
    },
  },
  methods: {
    ...mapActions('order', ['generateInvoice']),
    maskPhoneNumber: maskPhoneNumber,
    appliedCouponName: appliedCouponName,
    getCardType: getCardType,
    downloadOrderInvoice(orderFulfillments) {
      let orderId = orderFulfillments.map(
        (orderFulfillment) => orderFulfillment.orderID
      )
      if (orderId) {
        this.generateInvoice({
          orderId,
          showLoader: true,
          invoiceErrorAlertKey: 'orderCompleteAlert',
        })
          .then((response) => {
            if (response?.data && response.data instanceof Blob) {
              const file = new Blob([response.data], {
                type: 'application/pdf',
              })
              const fileURL = window.URL.createObjectURL(file)
              const link = document.createElement('a')
              link.href = fileURL
              link.download =
                'Order-' + this.recentOrderDetail.orderNumber + '.pdf'
              document.body.appendChild(link)
              link.click()
              document.body.removeChild(link)
            }
          })
          .catch((error) => {})
      }
    },
    getSubFrequency(frequency) {
      return `${frequency} ${frequency > 1 ? 'Days' : 'Day'}`
    },
  },
  destroyed() {
    this.$store.commit('order/SET_RECENT_ORDER', null)
    this.$store.commit('order/DELETE_ORDER_DETAIL', this.orderNumber)
    if (this.orderCompleteAlert)
      this.$store.commit('order/SET_ORDER_ALERT', {
        alertKey: 'orderCompleteAlert',
        alertData: null,
      })
  },
  async beforeMount() {
    // prefetch code
    let { orderNumber } = this.$route.params,
      recentOrderNumber = this.$store.getters['order/recentOrderNumber'],
      isLoggedIn = this.$store.getters['auth/isLoggedIn'],
      orderInfo = this.$store.getters['order/orderDetailById'](orderNumber)

    //  order complete page on refresh
    if (!orderInfo || !orderInfo.orderID) {
      await this.$store.dispatch('cart/getOrderThankYou', orderNumber)
      orderInfo = this.$store.getters['order/orderDetailById'](orderNumber)
      recentOrderNumber = this.$store.getters['order/recentOrderNumber']
    }

    if (orderNumber !== recentOrderNumber) {
      return this.$router.replace({
        name: 'Orders',
      })
    }

    if (!isLoggedIn && orderInfo.customerID)
      await this.$store.dispatch(
        'cart/fetchGuestUserInfo',
        orderInfo.customerID
      )
    // prefetch code

    const _this = this
    if (
      !this.isLoggedIn &&
      !this.guestUser &&
      this.recentOrderDetail?.customerID
    )
      await _this.$store.dispatch(
        'cart/fetchGuestUserInfo',
        this.recentOrderDetail.customerID
      )
    _this.$store.commit('cart/SET_ACKNOWLEDGEMENT_ACCEPTED', false)
    if (!this.isLoggedIn) {
      _this.$store.commit('guest/SET_GUEST_CART_NUMBER', null)
      _this.$store.dispatch('reserveSlot/resetSlots', null)
      _this.$store.commit('address/SET_CUSTOMER_ADDRESSES', {})
      _this.$store.commit('address/SET_CUSTOMER_BILLING_ADDRESSES', {})
      _this.$store.commit('payment/SET_PAYMENT_METHODS', {})
      // if (!_this.businessConfigurations.enableBetaCustomerReview)
      //   _this.$store.commit('guest/SET_GUEST_USER', null)
    }
    this.$nextTick(
      debounce(() => {
        _this.$store
          .dispatch('customerReview/customerReviewSubmittedByOrder', {
            orderID: _this.recentOrderDetail.orderID,
          })
          .then((isCustomerReviewSubmitted) => {
            let properties = {
              orderID: _this.recentOrderDetail.orderID,
            }
            if (!_this.isLoggedIn && this.recentOrderDetail.customerID)
              properties.customerID = this.recentOrderDetail.customerID

            _this.changeDialogState({
              dialog: 'WriteFeedback',
              val:
                _this.businessConfigurations.isBetaEnabled &&
                _this.businessConfigurations.enableBetaCustomerReview &&
                !isCustomerReviewSubmitted,
              properties,
            })
          })
      }, 150)
    )
  },
}
</script>
<style lang="scss">
.completePage {
  .page-title {
    margin-bottom: 10px;
    h1 {
      margin: 0;
      padding: 0;
      font-size: 20px;
      font-family: 'Poppins', sans-serif;
      font-weight: 500;
      line-height: normal;
      color: #0f141a;
      span {
        margin-left: 5px;
        font-size: 16px;
        font-family: 'Lato', sans-serif;
        color: #888888;
      }
      @media (min-width: 1681px) {
        font-size: 24px;
        span {
          font-size: 18px;
        }
      }
      @media (max-width: 599px) {
        span {
          display: block;
        }
      }
    }
  }
  /* Content */
  .page-content {
    padding: 20px 0;
    @media (min-width: 1024px) {
      padding: 40px 0;
    }
  }
  .sectionInner {
    display: flex;
    flex-direction: column;
    margin-left: -15px;
    margin-right: -15px;
    @media (min-width: 1024px) {
      flex-direction: row;
    }
    .sectionLeft {
      flex: 10000 0 0%;
      padding: 0 15px;
    }
    .sectionRight {
      width: 360px;
      padding: 0 15px;
      @media (max-width: 1023px) {
        width: 100%;
        position: relative;
        box-shadow: none;
      }
    }
  }

  /* Order Item */
  .orderItems {
    padding-top: 20px;
  }
  /* Order Item */

  /* Order Summary */
  .orderCompleteSummary {
    margin: 20px 0;
    background: #fff;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.15);
    border-radius: 10px;
    h3 {
      margin: 0;
      padding: 10px 0;
      background: #314154;
      color: #fff;
      font-size: 18px;
      font-family: 'Poppins', sans-serif;
      font-weight: 500;
      line-height: normal;
      text-align: center;
      border-radius: 10px 10px 0 0;
      @media (min-width: 1681px) {
        font-size: 20px;
      }
    }
    .orderSummaryInner {
      padding: 20px;
    }
    .orderSummaryTotal {
      padding-bottom: 15px;
      ul {
        margin: 0;
        padding: 0;
        list-style: none;
        li {
          padding: 6px 0;
          font-size: 16px;
          font-weight: normal;
          color: #0f141a;
          position: relative;
          text-align: right;
          span {
            font-weight: normal;
            width: 200px;
            position: absolute;
            left: 0;
            top: 6px;
            text-align: left;
            color: #333;
          }
          &.total {
            margin-top: 8px;
            padding: 15px 0 5px;
            border-top: 1px solid rgba(112, 112, 112, 0.25);
            font-size: 18px;
            font-weight: 700;
            color: #314154;
            span {
              top: 15px;
              font-weight: 700;
            }
          }
        }
        p.applied-coupons {
          font-weight: 700;
          text-align: left;
          color: #333;
        }
      }
    }
    .orderSummaryAction {
      p {
        color: #888888;
        font-size: 14px;
        a {
          font-size: 16px;
        }
        &:last-child {
          margin: 0;
        }
      }
    }
    .orderSummaryDiscount {
      margin-bottom: 20px;
      background: #fafff5;
      border: 1px solid #b3e59e;
      .q-item {
        min-height: 64px;
        .q-item__section--avatar {
          min-width: 45px;
          padding-right: 10px;
        }
        .q-item__section--main {
          font-size: 16px;
          color: #0f141a;
          span {
            font-size: 10px;
            display: block;
          }
        }
        .q-item__section--side {
          font-size: 18px;
          font-weight: bold;
          color: #488f2a;
        }
        .q-focusable {
          display: none;
        }
      }
      .q-card {
        background: #fafff5;
        .q-card__section {
          padding: 0 20px;
        }
        ul {
          border-top: 1px solid #b3e59e;
          margin: 0;
          padding: 10px;
          list-style: none;
          li {
            padding: 5px 0;
            font-size: 12px;
            font-weight: bold;
            color: #333333;
            position: relative;
            text-align: right;
            span {
              width: 140px;
              position: absolute;
              left: 0;
              top: 6px;
              text-align: left;
            }
          }
        }
      }
    }

    @media (max-width: 767px) {
      margin: 30px 0 15px;
      box-shadow: none;
      h3 {
        background: #fafafa;
        color: $tertiary;
        display: block;
        border-radius: 0;
      }
      &:after {
        content: '';
        display: table;
        clear: both;
      }
      .orderSummaryInner {
        padding: 0;
      }
      .orderSummaryPromocode,
      .orderSummaryTotal {
        display: block;
        padding: 15px;
      }
      .orderSummaryAction {
        p {
          display: block;
        }
        .form-action {
          display: block;
          margin: 0 15px;
          padding-bottom: 0;
          & + .form-action {
            width: calc(65% - 30px);
          }
          &.continue {
            display: block;
          }
          .total {
            font-size: 22px;
            font-weight: 600;
            color: $tertiary;
            text-align: left;
            span {
              color: #888888;
              display: block;
              font-size: 12px;
            }
          }
          .q-btn__wrapper {
            padding: 0 10px;
            min-height: 44px;
          }
        }
      }
    }
  }
  /* Order Summary */

  /* Order Thank You */
  .orderThankyou {
    margin: 20px 0;
    background: #fcfffc;
    border: 2px solid #a1d4a2;
    font-size: 15px;
    font-weight: normal;
    color: #666666;
    border-radius: 10px;
    padding: 20px;
    @media (min-width: 1440px) {
      padding: 40px 20px;
    }
    @media (min-width: 1681px) {
      font-size: 18px;
    }
    @media (max-width: 1023px) {
      margin-top: 0;
    }
    .checki {
      width: 60px;
      height: 60px;
      font-size: 60px;
      margin-bottom: 15px;
      @media (min-width: 1440px) {
        width: 94px;
        height: 94px;
        font-size: 94px;
      }
    }
    h1 {
      margin: 0 0 15px;
      padding: 0;
      font-family: 'Poppins', sans-serif;
      font-size: 24px;
      line-height: normal;
      font-weight: bold;
      color: #008a03;
      @media (min-width: 1681px) {
        font-size: 34px;
      }
    }
    a {
      color: $primaryOnBody;
      color: var(--q-color-primaryOnBody) !important;
    }
    .orderReviewLink {
      padding-top: 5px;
      a {
        color: $tertiary;
        i {
          margin-left: 10px;
          transform: rotate(90deg);
        }
      }
    }
    .trackCompleteList {
      list-style: none;
      margin: 0;
      padding: 0;
      .row {
        align-items: flex-end;
      }
      li {
        margin: 20px 0;
        padding: 0;
        font-size: 14px;
        .hd {
          font-size: 16px;
          font-weight: bold;
        }
        .link {
          font-size: 12px;
        }
        p {
          margin: 0;
          padding: 0;
        }
        @media (max-width: 767px) {
          position: relative;
          padding-left: 50px;
          i {
            position: absolute;
            left: 8px;
            top: 15px;
            font-size: 24px;
          }
          .link {
            padding-top: 8px;
          }
        }
      }
    }
  }
  /* Order Thank You */

  /* Order Information */
  .orderReviewInformation {
    padding: 5px 10px;
    margin-bottom: 20px;
    .title {
      h2 {
        margin: 0;
        padding: 0;
        font-size: 18px;
        line-height: normal;
        font-weight: bold;
        color: $tertiary;
        @media (min-width: 1681px) {
          font-size: 20px;
        }
      }
      a {
        font-size: 14px;
        color: #888888;
        text-decoration: none;
        @media (min-width: 1681px) {
          font-size: 18px;
        }
      }
    }
    .orderItems {
      .link-products {
        .OrdetItemTotal {
          min-width: 90px !important;
        }
        .cartProductSide {
          width: 86px;
          padding-left: 0;
          padding-right: 10px;
        }
        .linked-product {
          .q-item {
            padding-right: 0;
            padding-left: 10px;
            @media (max-width: 1023px) {
              display: flex;
              @media (max-width: 599px) {
                flex-wrap: wrap;
              }
              .cartProductSide {
                text-align: right;
              }
              .order-complete-qty {
                @media (max-width: 599px) {
                  width: 100%;
                  text-align: left;
                  order: 4;
                  display: flex;
                  align-items: flex-end;
                  max-width: none;
                  min-width: 100%;
                  margin-left: 0;
                  padding-right: 15px;
                }
              }
            }
          }
          .order-complete-qty {
            min-width: 120px;
            max-width: 120px;
          }
        }
      }
    }
    @media (max-width: 991px) {
      padding: 5px 0px;
    }
  }
  /* Order Information */

  /* Order Review Info */
  .orderReviewInfo {
    h5 {
      margin: 0 0 10px;
      padding: 0;
      font-size: 18px;
      font-weight: bold;
      line-height: normal;
      color: $tertiary;
    }
    p {
      margin: 0;
      font-size: 16px;
      line-height: 24px;
      color: #888888;
      .default {
        background: #91bf5b;
        color: #fff;
        font-size: 12px;
        line-height: 12px;
        display: inline-block;
        padding: 3px 10px;
        border-radius: 12px;
      }
    }
  }
  /* Order Review Info */

  @media (max-width: 1023px) {
    padding-bottom: 0;
  }
}
</style>
